<template>
  <b-container id="input-form" >
    <h2>Admin only (for pictures upload)</h2>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="input-group-1"
        label="Email address:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          class="input-field mx-auto"
          v-model="userData.email"
          type="email"
          required
          placeholder="Enter email"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Password:" label-for="input-2">
        <b-form-input
          id="input-2"
          class="input-field mx-auto"
          v-model="userData.password"
          required
          placeholder="Enter password"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-button class="col log-buttons" type="submit" variant="primary">Login</b-button>
    </b-form>
  </b-container>
</template>

<script>
import { auth } from '../../plugins/firebase';
import router from '@/router/index';

export default {
    name: 'Login',
    data() {
        return {
            userData: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
      async onSubmit() {
        try {
          await auth.signInWithEmailAndPassword(this.userData.email, this.userData.password)
          router.replace('/admin');
        } catch(e) {
          console.log(e)
        }
      }
    }
}
</script>

<style scoped>
  #input-form {
    margin: 8% auto;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .input-field {
    width: 50%;
  }
  .log-buttons {
    margin: 5px;
    width: 15%;
  }
  h2 {
      margin-bottom: 5vh;
  }
</style>